import axios from "axios";
import moment from "moment";

export default {

    data: () => ({
        months: '',
        date: [],
        min: '',
        max: '',
        modal: false,
        start_date: '',
        end_date: '',

        stats: {},
        mounth: [],
        averageTimeUsersSpendOnApp: "",

        series1: [],
        series2: [],
        series3: [],
        series4: [],
        series5: [],
        series6: [],
        series7: [],
        series8: [],

        // charts

        chartOptions31: {},

        chartOptionsday: {
            chart: {
                height: '250px',
                type: 'line',
                zoom: {
                    enabled: true
                },
            },
            // tooltip: {
            //     enabled: false,
            // },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                labels: {
                    formatter: (val) => {
                        return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

                    }
                }
            },
            xaxis: {
                tickAmount: 4,
                categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
            }
        },

        chartOptionsweek: {
            chart: {
                height: '250px',
                type: 'line',
                zoom: {
                    enabled: true
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 2,
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                labels: {
                    formatter: (val) => {
                        return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)
                    }
                }
            },
            xaxis: {
                categories: [ 'su', 'mo', 'tu', 'we', 'th', 'fr' ,'sa'],
            }
        },

        chartOptionsplatform: {
            chart: {
                height: '250px',
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                },
                toolbar: {
                    show: false
                },
            },
            // tooltip: {
            //     enabled: false,
            // },
            colors: ['#E91E63', '#3F51B5', '#009688'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            yaxis: {
                labels: {
                    formatter: (val) => {
                        return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

                    }
                }
            },
            xaxis: {
                categories: [
                    ['Instagram'],
                    ['TikTok'],
                    ['Both'],

                ],
                labels: {
                    style: {
                        //   colors: ['red' , 'green' , 'blue'],
                        fontSize: '12px'
                    }
                }
            }
        },

        chartOptionsmembership: {
            chart: {
                height: '250px',
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        // console.log(chart, w, e)
                    }
                },
                toolbar: {
                    show: false
                },
            },
            colors: ['#E65100', '#546E7A', '#FF8F00'],
            // tooltip: {
            //     enabled: false,
            // },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            yaxis: {
                labels: {
                    formatter: (val) => {
                        return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

                    }
                }
            },
            xaxis: {
                categories: [
                    ['Bronze'],
                    ['Silver'],
                    ['Gold'],

                ],
                labels: {
                    style: {
                        //   colors: ['red' , 'green' , 'blue'],
                        fontSize: '12px'
                    }
                }
            }
        },


    }),

    beforeMount() {
        // this.get_date()
    },
    mounted() {
        this.get_stats()
        // this.min = new Date().getFullYear() + '-' +'01-01'
        // this.max = new Date().getFullYear() + '-' +'12-31'
        console.log(this.min);
    },

    methods: {
        checkdate() {
            let date1 = new Date(this.date[0]).getTime()
            let date2 = new Date(this.date[1]).getTime()

            if (date1 > date2) {
                this.start_date = this.date[1]
                this.end_date = this.date[0]
            }
            else {
                this.start_date = this.date[0]
                this.end_date = this.date[1]
            }

            // const listDate = [];
            // const startDate = this.start_date;
            // const endDate = this.end_date;
            // const dateMove = new Date(startDate);
            // let strDate = startDate;

            // while (strDate < endDate) {
            //     strDate = dateMove.toISOString().slice(0, 10);
            //     listDate.push(moment(strDate).format('DD-MM'));
            //     dateMove.setDate(dateMove.getDate() + 1);
            // };
            // console.log(listDate);

            // this.chartOptions31 = {
            //     chart: {
            //         height: 250,
            //         type: 'line',
            //         zoom: {
            //             enabled: true
            //         },
            //         // toolbar: {
            //         //     show: false
            //         //   },
            //     },
            //     dataLabels: {
            //         enabled: false
            //     },
            //     // tooltip: {
            //     //     enabled: false,
            //     // },
            //     stroke: {
            //         show: true,
            //         curve: 'smooth',
            //         lineCap: 'butt',
            //         colors: undefined,
            //         width: 2,
            //         dashArray: 0,
            //     },
            //     grid: {
            //         row: {
            //             colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            //             opacity: 0.5
            //         },
            //     },
            //     yaxis: {
            //         labels: {
            //             formatter: (val) => {
            //                 return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

            //             }
            //         }
            //     },
            //     xaxis: {
            //         tickAmount: 4,
            //         categories: listDate,

            //     }

            // }


            this.get_stats()
        },

        async get_stats() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/stats',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    startDate: this.start_date || undefined,
                    endDate: this.end_date || undefined,
                }

            })
                .then((res) => {
                    console.log(res.data);
                    this.stats = res.data.data.stat
                    this.create_series()
                    this.create_time()
                })
                .catch((err) => {
                    console.log(err);
                    // this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })

                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        create_series() {
            let ndata1 = []
            let ndata2 = []
            let ndata3 = []
            let ndata4 = []
            let ndata5 = []
            let ndata6 = []
            let ndata7 = []
            let ndata8 = []
            this.mounth = []

            for (let i = 0; i < this.stats.numberOfUsersRegistered.length; i++) {
                this.mounth.push(this.stats.numberOfUsersRegistered[i].createdAt)
                ndata1.push(this.stats.numberOfUsersRegistered[i].total) // mounth
                // ndata2.push(this.stats.numberOfLiveCampaigns[i].total) // mounth
                // ndata3.push(this.stats.numberOfBusinessesRegistered[i].total) // mounth
                // ndata4.push(this.stats.numberOfUsersRegistered[i].total) //
                ndata5.push(this.stats.userActivityLast30Days[i].total) // mounth
            }
            for (let i = 0; i < this.stats.numberOfBusinessesRegistered.length; i++) {
                ndata3.push(this.stats.numberOfBusinessesRegistered[i].total) // mounth
            }
            for (let i = 0; i < this.stats.numberOfLiveCampaigns.length; i++) {
                ndata2.push(this.stats.numberOfLiveCampaigns[i].total) // mounth
            }
            for (let i = 0; i < this.stats.daysPeopleAreMoreActive.length; i++) {
                ndata6.push(this.stats.daysPeopleAreMoreActive[i].total) // week
            }
            for (let i = 0; i < this.stats.timesOfDayPeopleAreMoreActive.length; i++) {
                ndata7.push(this.stats.timesOfDayPeopleAreMoreActive[i].total) // day
            }
            for (let i = 0; i < this.stats.venueMemberships.length; i++) {
                ndata8.push(this.stats.venueMemberships[i].total) // day
            }

            this.series1 = [{ name: "Total", data: ndata1 }] // mounth
            this.series2 = [{ name: "Total", data: ndata2 }] // mounth
            this.series3 = [{ name: "Total", data: ndata3 }] // mounth
            this.series4 = [{ name: "Total", data: [this.stats.numberOfUsersWithInstagram, this.stats.numberOfUsersWithTiktok,this.stats.numberOfUsersWithTiktok] }]
            this.series5 = [{ name: "Total", data: ndata5 }] // mounth
            this.series6 = [{ name: "Total", data: ndata6 }] // week
            this.series7 = [{ name: "Total", data: ndata7 }] // day
            this.series8 = [{ name: "Total", data: ndata8 }] // membership

            console.log(this.series6);
            console.log(this.series7);

            this.chartOptions31 = {
                chart: {
                    height: '250px',
                    type: 'line',
                    zoom: {
                        enabled: true
                    },
                   
                },
                dataLabels: {
                    enabled: false
                },

                stroke: {
                    show: true,
                    curve: 'smooth',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

                        }
                    }
                },
                xaxis: {
                    tickAmount: 4,
                    categories: this.mounth,

                }

            }

        },

        // get_date() {
        //     let labels = [];
        //     const popArray = () => {
        //         for (let i = 0; i <= 31; i++) {
        //             labels.push(moment().subtract(i, "days").format('DD-MM'))
        //         }
        //         this.mounth = labels.reverse()
        //     }
        //     popArray();

        //     this.chartOptions31 = {
        //         chart: {
        //             height: 250,
        //             type: 'line',
        //             zoom: {
        //                 enabled: true
        //             },
        //             // toolbar: {
        //             //     show: false
        //             //   },
        //         },
        //         dataLabels: {
        //             enabled: false
        //         },

        //         stroke: {
        //             show: true,
        //             curve: 'smooth',
        //             lineCap: 'butt',
        //             colors: undefined,
        //             width: 2,
        //             dashArray: 0,
        //         },
        //         grid: {
        //             row: {
        //                 colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //                 opacity: 0.5
        //             },
        //         },
        //         yaxis: {
        //             labels: {
        //                 formatter: (val) => {
        //                     return val >= 1000 && val < 1000000 ? (val / 1000).toFixed(1) + 'K' : val >= 1000000 ? (val / 1000000).toFixed(1) + 'M' : val.toFixed(1)

        //                 }
        //             }
        //         },
        //         xaxis: {
        //             tickAmount: 4,
        //             categories: this.mounth,

        //         }

        //     }
        // },

        create_time(data) {
            // 1- Convert to seconds:
            let seconds = data / 1000;
            // 2- Extract hours:
            const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
            seconds = seconds % 3600; // seconds remaining after extracting hours
            // 3- Extract minutes:
            const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
            // 4- Keep only seconds not extracted to minutes:
            seconds = (seconds % 60).toFixed(0);
            // alert(hours + ":" + minutes + ":" + seconds);
            return hours + "h " + minutes + "m " + seconds + "s "
        },
    }
};