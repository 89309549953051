import axios from "axios";

export default {

    data: () => ({
        camp_dialog: false,
        selected: {},
        campaigns: [],
    }),

    mounted() {
        this.get_campaigns()
    },

    methods: {

        async get_campaigns() {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'GET',
                url: '/admin/campaigns/pendings',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                params: {
                    condition: this.search_type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.campaigns = res.data.data.campaigns

                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        async verify(type) {
            this.$store.commit('progress_status', true)
            await axios({
                method: 'PATCH',
                url: '/admin/campaigns/pendings/' + this.selected._id + '/verify',
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token")
                },
                data: {
                    verify: type
                }
            })
                .then((res) => {
                    console.log(res.data);
                    this.camp_dialog = false
                    this.get_campaigns()
                    if (type === true) {
                    this.$store.commit('snackbar', { color: 'green', text: 'Campaign Accepted.' })
                    }
                    else{
                    this.$store.commit('snackbar', { color: 'green', text: 'Campaign Rejected.' })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit('snackbar', { color: 'red', text: err.response.data.errors })
                })
                .finally(() => {
                    this.$store.commit('progress_status', false)
                })
        },

        open_dialog(info) {
            this.camp_dialog = true
            this.selected = info
            console.log(this.selected);
        },

        open_link(link) {
            window.open('http://' + link, '_blank')
        }
    }
};